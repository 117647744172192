import React from 'react';
import {Redirect} from 'react-router-dom';
import {observer, inject} from 'mobx-react';
import Nav from '../Components/nav';
import Button from 'react-bootstrap/Button';
import ErrorMessage from '../Components/errorMessage';
import SpinLoader from '../Components/spinLoader';
import PropTypes from 'prop-types';
import {
  WELCOME_PAGE_STANDARD_WELCOME,
  WELCOME_PAGE_HOST_TERMINATED_MEETING,
} from '../Constants/textResources';

class WelcomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      meetingCode: '',
      meetingCodeValidated: this.props.meetingCodeValidated,
      inputMode: null,
      redirect: this.props.redirect,
      showLoading: false,
      invalidMeetingCode: false,
      loaderMessage: 'Verifying meeting code',
      showProgress: false,
    };

    this.validateCode = this.validateCode.bind(this);
    this.showProgress = this.showProgress.bind(this);
    this.endProgress = this.endProgress.bind(this);
    this.errorOccured = this.errorOccured.bind(this);
    this.renderRedirect = this.renderRedirect.bind(this);
    this._handleChange = this._handleChange.bind(this);
    this.getWelcomeMessage = this.getWelcomeMessage.bind(this);
  }

  validateCode = () => {
    this.showProgress();

    setTimeout(() => {
      this.props.validateMeetingCode(this.state.meetingCode, (validationStatus) => {
        this.endProgress();

        if (validationStatus.isValid) {
          this.setState({redirect: `/join/${this.state.meetingCode}`});
        } else {
          if (validationStatus.waitingForHost) {
            //redirect to join page but indicate that host is waiting
            this.setState({
              redirect: `/join/${this.state.meetingCode}?awaitHost=true`,
            });
          } else {
            //invalidate meeting
            this.setState({
              invalidMeetingCode: true,
              inputMode: false,
              errorMessage: this.props.lastErrorMessage,
            });
          }
        }
      });
    }, 1500);
  };

  showProgress = () => {
    this.setState({
      showProgress: true,
    });
  };

  endProgress = () => {
    this.setState({
      showProgress: false,
    });
  };

  inputModeIsOn = () => {
    return this.state.inputMode === true;
  };

  errorOccured = () => {
    return !this.inputModeIsOn() && !this.props.sessionIsValid;
  };

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
  };

  _handleChange = (e) => {
    this.setState({
      invalidMeetingCode: false,
      inputMode: true,
      meetingCode: e.target.value,
    });
  };

  getWelcomeMessage = () => {
    return this.props.meetingWasTerminatedByHost
      ? WELCOME_PAGE_HOST_TERMINATED_MEETING
      : WELCOME_PAGE_STANDARD_WELCOME;
  };

  render() {
    return (
      <>
        {this.renderRedirect()}
        <Nav {...this.props} />
        <div
          style={{
            textAlign: 'center',
            margin: 'auto',
          }}>
          <p>{this.getWelcomeMessage()}</p>
          <input
            type='text'
            placeholder='Meeting Code'
            name='meetingCode'
            onChange={this._handleChange}
            tabIndex='0'
            style={{
              width: '230px',
              height: '38px',
              marginRight: '10px',
              textAlign: 'center',
            }}></input>
          <Button
            id='btn-go'
            onClick={this.validateCode}
            style={{verticalAlign: 'baseline'}}>
            Go
          </Button>
          <ErrorMessage
            show={this.errorOccured}
            status={this.props.status}
            lastErrorMessage={this.props.lastErrorMessage}
          />
          {this.state.invalidMeetingCode && (
            <ErrorMessage
              show={() => true}
              message={this.state.errorMessage}
              customStyle={{
                textAlign: 'center',
                margin: '0 auto',
                marginTop: '10px',
                marginBottom: '10px',
                maxWidth: '40%',
              }}
            />
          )}
          {this.state.showProgress && (
            <SpinLoader message={this.state.loaderMessage} show={true} />
          )}
        </div>
      </>
    );
  }
}

WelcomePage.propTypes = {
  context: PropTypes.object.isRequired,
  meetingCodeValidated: PropTypes.bool.isRequired,
  sessionIsValid: PropTypes.bool.isRequired,
  redirect: PropTypes.string,
  status: PropTypes.string,
  lastErrorMessage: PropTypes.string,
  deviceType: PropTypes.string.isRequired,
  validateMeetingCode: PropTypes.func.isRequired,
  meetingWasTerminatedByHost: PropTypes.bool.isRequired,
};

export default inject('context')(observer(WelcomePage));

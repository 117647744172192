import React from 'react';
import PropTypes from 'prop-types';
import InnerModal from './Modals/innerModal';
import SelectAudio from './selectAudio';
import SelectCamera from './selectCamera';
import UserInfo from './userInfo';
import ErrorMessage from './errorMessage';
import {SKIP_MEDIA_SELECTION_FOR_MOBILE} from '../Helpers/configHelper';
import {inject, observer} from 'mobx-react';
import RecordingConsentModal from './Modals/recordingConsentModal';

class MediaSelector extends React.Component {
  constructor(props) {
    super(props);

    this.gotoPrevious = this.gotoPrevious.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.hasPrevious = this.hasPrevious.bind(this);
    this.hasNext = this.hasNext.bind(this);
    this.isLastInQueue = this.isLastInQueue.bind(this);
    this.isSelectionComplete = this.isSelectionComplete.bind(this);
    this.hasValidationErrors = this.hasValidationErrors.bind(this);
    this.state = {
      currentModalIndex: 0,
      selectionComplete: false,
      displayRecordingConsent: false,
      redirect: null,
    };
  }

  gotoPrevious = (index) => {
    if (this.hasPrevious(index)) this.setState({currentModalIndex: index - 1});
  };

  gotoNext = (index, numOfModals, skipValidation) => {
    if (!skipValidation) this.props.context.UI.validateFields();

    if (this.hasNext(index, numOfModals) && !this.hasValidationErrors()) {
      this.setState({currentModalIndex: index + 1});
    } else if (
      !this.hasValidationErrors() &&
      this.props.hasValidSession &&
      this.props.status !== 'error'
    ) {
      this.setState({
        selectionComplete: true,
      });

      if (this.props.showRecordingConsent) {
        this.setState({displayRecordingConsent: true});
      } else {
        //Last modal - Proceed to connect to a room
        this.props.markMediaSelectionDone();
      }
    }
  };

  isSelectionComplete = () => {
    return this.state.selectionComplete;
  };

  hasPrevious = (index) => {
    return index - 1 >= 0;
  };

  hasNext = (index, numOfModals) => {
    return index + 1 < numOfModals;
  };

  hasValidationErrors = () => {
    return this.props.context.UI.hasValidationErrors();
  };

  isLastInQueue = (index, numOfModals) => {
    return index === numOfModals - 1;
  };

  getValidModals = () => {
    let modals = [];
    let count = 0;
    let isMobile = this.props.context.Conference.eventData.isMobile;
    let hasUserModal =
      !(isMobile && SKIP_MEDIA_SELECTION_FOR_MOBILE) &&
      (this.props.userName === null ||
        (this.props.userName && !this.props.userName.length));
    let hasMicDialog =
      this.props.hasAudioInputDevices &&
      !(
        isMobile &&
        SKIP_MEDIA_SELECTION_FOR_MOBILE &&
        this.props.localAudioInputDevices.length === 1
      );
    let hasCameraDialog =
      this.props.hasVideoDevices &&
      !(
        isMobile &&
        SKIP_MEDIA_SELECTION_FOR_MOBILE &&
        this.props.localVideoInputDevices.length === 1
      );
    let numOfModals = +!!hasUserModal + +!!hasMicDialog + +!!hasCameraDialog;
    let skipValidation = !hasUserModal;
    var props = {
      currentlyVisibleModal: this.state.currentModalIndex,
      numOfModals: numOfModals,
      skipValidation: skipValidation,
      isSelectionComplete: this.isSelectionComplete,
      isLastInQueue: this.isLastInQueue,
      gotoPrevious: this.gotoPrevious,
      gotoNext: this.gotoNext,
    };

    if (hasUserModal) {
      let userInfo = (
        <InnerModal
          hidePreviousBtn={count === 0}
          index={count++}
          headerTitle='User Information'
          {...props}>
          <UserInfo />
        </InnerModal>
      );

      modals.push(userInfo);
    }

    let selectAudio;
    if (hasMicDialog) {
      selectAudio = (
        <InnerModal
          hidePreviousBtn={count === 0}
          index={count++}
          headerTitle='Audio Settings'
          {...props}>
          <SelectAudio
            localAudioInputDevices={this.props.localAudioInputDevices}
            localAudioOutputDevices={this.props.localAudioOutputDevices}
            setCurrentAudioInputDeviceId={this.props.setCurrentAudioInputDeviceId}
            setCurrentAudioOutputDeviceId={this.props.setCurrentAudioOutputDeviceId}
            {...this.props}
          />
        </InnerModal>
      );
      modals.push(selectAudio);
    }

    let selectCamera;

    if (hasCameraDialog) {
      selectCamera = (
        <InnerModal
          hidePreviousBtn={count === 0}
          index={count}
          headerTitle='Video Settings'
          {...props}>
          <SelectCamera
            localVideoInputDevices={this.props.localVideoInputDevices}
            setCurrentVideoDeviceId={this.props.setCurrentVideoDeviceId}
            {...this.props}
          />
        </InnerModal>
      );

      modals.push(selectCamera);
    }

    return modals;
  };

  redirectToHome = () => {
    this.setState({redirect: '/'});
  };

  render() {
    if (
      this.props.devicesPopulated &&
      this.props.hasAudioOrVideoDevices &&
      !this.props.mediaSelectionComplete &&
      this.props.hasValidSession &&
      !this.state.displayRecordingConsent
    ) {
      const modals = this.getValidModals();

      return (
        <div className={!this.props.show ? 'hide' : ''}>
          {modals.map((modal, i) => {
            return <div key={i}> {modal}</div>;
          })}
        </div>
      );
    } else if (
      !this.props.devicesLoading &&
      this.props.devicesPopulated &&
      !this.props.hasAudioOrVideoDevices &&
      !this.state.displayRecordingConsent
    ) {
      return (
        <ErrorMessage
          show={() => true}
          message='Sorry, no audio or video devices could be detected!'
        />
      );
    } else if (this.state.displayRecordingConsent) {
      return (
        <RecordingConsentModal
          onConsentGranted={this.props.markMediaSelectionDone}
          onCancel={this.redirectToHome}
          redirect={this.state.redirect}></RecordingConsentModal>
      );
    } else {
      return null;
    }
  }
}

MediaSelector.propTypes = {
  context: PropTypes.object.isRequired,
  deviceType: PropTypes.string.isRequired,
  userName: PropTypes.string,
  status: PropTypes.string.isRequired,
  localAudioInputDevices: PropTypes.array.isRequired,
  localAudioOutputDevices: PropTypes.array.isRequired,
  localVideoInputDevices: PropTypes.array.isRequired,
  setCurrentAudioInputDeviceId: PropTypes.func.isRequired,
  setCurrentAudioOutputDeviceId: PropTypes.func.isRequired,
  setCurrentVideoDeviceId: PropTypes.func.isRequired,
  markMediaSelectionDone: PropTypes.func.isRequired,
  hasValidSession: PropTypes.bool.isRequired,
  devicesPopulated: PropTypes.bool.isRequired,
  hasAudioOrVideoDevices: PropTypes.bool.isRequired,
  mediaSelectionComplete: PropTypes.bool.isRequired,
  hasAudioInputDevices: PropTypes.bool.isRequired,
  hasVideoDevices: PropTypes.bool.isRequired,
  show: PropTypes.bool.isRequired,
  devicesLoading: PropTypes.bool.isRequired,
  showRecordingConsent: PropTypes.bool,
  setJoinMeetingWithCameraTurnedOff: PropTypes.func.isRequired,
  joinMeetingWithCameraTurnedOff: PropTypes.bool.isRequired,
  setJoinMeetingWithMicMuted: PropTypes.func.isRequired,
  joinMeetingWithMicMuted: PropTypes.bool.isRequired,
};

export default inject('context')(observer(MediaSelector));
